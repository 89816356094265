import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CONFIG_TOKEN, ContentPath } from 'core/constants';
import { IXMOptions } from 'core/interfaces';
import { Subscription } from 'rxjs';
import { Accessibility } from 'services/accessibility';
import { CimaUrl, WindowReference } from 'services/index';
import { SitecoreClient } from 'services/sitecore/SitecoreClient';

@Component({
    selector: 'cbm-sign-in-modal',
    templateUrl: 'sign-in.html',
    styleUrls: ['sign-in.scss']
})

export class CbmSignInModal implements OnInit {

    public pageData: FlexibleImageBannerPageData;
    public actions: CmsBannerActions;
    public shopInternetLink: string;
    public isMobile: boolean;
    public isTablet: boolean;

    private dialogRef: MatDialogRef<CbmSignInModal>;
    private sitecoreWebClient: SitecoreClient;
    private config: IXMOptions;
    private isSitecoreActive: boolean;
    private subscriptions: Subscription[] = [];
    private windowReference: WindowReference;

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions, accessibility: Accessibility, dialogRef: MatDialogRef<CbmSignInModal>,
        @Inject(MAT_DIALOG_DATA) _data: DescriptiveModalData, cimaUrl: CimaUrl, sitecoreWebClient: SitecoreClient, windowReference: WindowReference) {
        Object.assign(this, { config, accessibility, _data, dialogRef, cimaUrl, sitecoreWebClient, windowReference });
    }

    public ngOnInit(): void {
        this.isSitecoreActive = this.config.SITECORE_ACTIVE;

        this.subscriptions.push(this.windowReference.breakpoint.subscribe((breakpoint: Breakpoint) => {
            this.isMobile = breakpoint.isMobile;
            this.isTablet = breakpoint.isTablet;
        }));

        if (this.isSitecoreActive) {
            this.sitecoreWebClient.getData(ContentPath.SIGN_IN_MODAL_PATH).then((data) => {
                this.pageData = data.imageBanner;
                this.actions = this.pageData.actions;
                this.shopInternetLink = this.actions.links[0].link;
            });
        }
    }

    public close(action: string): void {
        const response: ModalResponse = {
            close: action === 'close',
            primary: action === 'primary',
            secondary: action === 'secondary'
        };
        this.dialogRef.close(response);
    }
}
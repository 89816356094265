/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiChannel } from 'core/constants';

import { ModelBase } from 'store/model-base';
import { ActivationInfo } from 'store/activation/models';
import { buildHeaders, XmStore } from 'core/services';
import { StoreAction } from 'store/actions';
import { LineCollection, LineDetail } from './models';
import { logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';

@Injectable()
export class LineApi {
    public static getLines(_xmStore: XmStore, http: HttpClient): Observable<LineCollection> {
        return http.get('/account/lines', { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }) }).pipe(
            map((linesResponse: LineDetail[]) => LineCollection.create<LineCollection>({ lines: linesResponse }), 
                catchError(err => logAndHandleError(err, '/account/lines', OperationType.GET)))
        );
    }

    public static getActivationInfoForLine(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<ActivationInfo[]> {
        const requests: Observable<object>[] = [];
        params.lineIds.forEach((key: string) => requests.push(http.get(`/lines/${key}/activation`, { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }) })));

        return forkJoin(requests).pipe(
            map((responses: ActivationInfo[]) => responses.map((lineInfo: ActivationInfo) => ActivationInfo.create<ActivationInfo>(lineInfo)), 
                catchError(err => logAndHandleError(err, '/lines/{key}/activation', OperationType.GET)))
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_LINE_COLLECTION] = LineApi.getLines;
ModelBase.fetchMapping[StoreAction.GET_ACTIVATION_INFO_FOR_LINE] = LineApi.getActivationInfoForLine;

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Cloudinary } from 'services/cloudinary';
import { IXMOptions } from 'core/interfaces';
import { CONFIG_TOKEN } from 'core/constants';

@Component({
    selector: 'xm-single-image-modal',
    templateUrl: 'single.html',
    styleUrls: [ 'single.scss' ]
})

export class SingleImageModal implements OnInit {
    public data: SingleImageModalData;
    public imageOptions: MediaImageOptions;
    public iconOptions: MediaImageOptions[];
    public config: IXMOptions;

    private dialogRef: MatDialogRef<SingleImageModal>;

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions, dialogRef: MatDialogRef<SingleImageModal>, @Inject(MAT_DIALOG_DATA) data: SingleImageModalData) {
        Object.assign(this, { data, dialogRef, config });

        document.addEventListener('click', (clickEvent: MouseEvent) => {
            this.handleClick(clickEvent);
        });

    }

    public ngOnInit(): void {
        this.imageOptions = {
            mobile: Cloudinary.generateMediaFromCms(this.data.headerIcon)
        };

        this.data.content.forEach((content: SingleImageModalContent) => {
            if (content.icon) {
                content.iconOptions = {
                    mobile: Cloudinary.generateMediaFromCms(content.icon)
                };
            }
        });
    }

    public handleClick(event: Event): void {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const target: HTMLTextAreaElement = event.target as HTMLTextAreaElement;

        const hasClass: boolean = target.classList.contains('cdk-overlay-backdrop-showing');

        if (hasClass) {
            this.dialogRef.close('close');
        }
    }
    
    public close(action: string): void {
        const response: ModalResponse = {
            close: action === 'close',
            primary: action === 'primary',
            secondary: action === 'secondary'
        };

        this.dialogRef.close(response);
    }
}

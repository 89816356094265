/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';

import { ModelBase } from 'store/model-base';
import { StoreAction } from 'store/actions';
import { OrderDetail } from 'store/order/models';
import { ApiChannel } from 'core/constants';
import { buildHeaders, XmStore } from 'core/services';
import { BuyInfo, User } from 'store/user/models';
import { logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';

@Injectable()
export class OrderApi {
    public static submitOrder(xmStore: XmStore, http: HttpClient, terms: ApiParams): Observable<OrderDetail> {
        return xmStore.peekChild<BuyInfo, User>('buyInfo', BuyInfo, User).pipe(
            mergeMap((buyInfo: BuyInfo) => http.post('/cart/submit', terms, { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY, sessionID: buyInfo.sessionId }) }).pipe(
                map((orderDetail: ApiResponse) => OrderDetail.create<OrderDetail>(orderDetail)), 
                catchError(err => logAndHandleError(err, '/cart/submit', OperationType.POST, undefined, JSON.stringify(terms), undefined, undefined, buyInfo.sessionId)))
            ),
            take(1)
        );
    }

    public static getOrderDetail(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<OrderDetail> {
        return http.get(`/orders/${params.orderId}`, { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }) }).pipe(
            map((orderDetail: OrderDetail) => OrderDetail.create<OrderDetail>(orderDetail), 
                catchError(err => logAndHandleError(err, '/orders/{params.orderId}', OperationType.GET)))
        );
    }

    public static deleteOrderDetail(_xmStore: XmStore, _http: HttpClient, params: ApiParams): Observable<OrderDetail> {
        return of(new OrderDetail(params));
    }
}

ModelBase.persistMapping[StoreAction.SUBMIT_ORDER] = OrderApi.submitOrder;
ModelBase.fetchMapping[StoreAction.GET_ORDER_DETAIL] = OrderApi.getOrderDetail;
ModelBase.deleteMapping[StoreAction.DELETE_ORDER_DETAIL] = OrderApi.deleteOrderDetail;

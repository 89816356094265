import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'xm-checkbox',
    styleUrls: ['./checkbox.scss'],
    templateUrl: './checkbox.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: XmCheckboxComponent
        }
    ]
})

export class XmCheckboxComponent implements ControlValueAccessor {
    @Input() public isSelected: boolean;
    @Input() public label: string;
    @Input() public inputId: string;
    @Input() public isOptional: boolean = false;
    @Input() public error: string;
    @Output() public toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

    public disabled: boolean = false;

    public onChange: Function;
    public onTouched: Function;

    public toggleSelected(event: boolean ): void {
        if (!this.isOptional) {
            this.onTouched(); // <-- mark as touched
            this.isSelected = event;
            this.onChange(event); // <-- call function to let know of a change
        }
       
        this.toggle.emit(event);
    }

    public writeValue(isSelected: boolean): void{
        this.isSelected = isSelected;
    }

    public registerOnChange(fn: () => {}): void {
        this.onChange = fn; // <-- save the function
    }

    public registerOnTouched(fn: () => {}): void {
        this.onTouched = fn; // <-- save the function
    }

    public setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ModelBase } from 'store/model-base';
import { StoreAction } from 'store/actions';
import { buildHeaders, XmStore } from 'core/services';
import { ApiChannel } from 'core/constants';
import { CallbackTime } from './models';
import { logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';

@Injectable()
export class CallbackApi {
    public static getCallbackTime(_xmStore: XmStore, http: HttpClient): Observable<CallbackTime> {
        return http.get('/callback', {
            headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY })
        }).pipe(map((response: CallbackTime) => CallbackTime.create<CallbackTime>(response)), 
            // eslint-disable-next-line @typescript-eslint/typedef
            catchError(err => logAndHandleError(err, '/callback', OperationType.GET)));
    }

    public static postCallbackNumber(_xmStore: XmStore, http: HttpClient, phoneNumber: string): Observable<CallbackTime> {
        return http.post(`/callback/${phoneNumber}`, undefined, {
            headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY })
        }).pipe(map((response: CallbackTime) => CallbackTime.create<CallbackTime>(response)), 
            // eslint-disable-next-line @typescript-eslint/typedef
            catchError(err => logAndHandleError(err, '/callback/{phoneNumber}', OperationType.POST)));
    }
}

ModelBase.fetchMapping[StoreAction.GET_CALLBACK_TIME] = CallbackApi.getCallbackTime;
ModelBase.persistMapping[StoreAction.SUBMIT_CALLBACK_NUMBER] = CallbackApi.postCallbackNumber;

import { Inject, Injectable } from '@angular/core';
import { UrlService } from '@uirouter/core';
import { CONFIG_TOKEN } from 'core/constants';
import { IXMOptions } from 'core/interfaces';

/*
    Functions:
    Functions cannot be nested
    There can be multiple different functions in one input string.

        1. Link with link style
        Example: text(Shop Now|https://www.xfinity.com|Shop Xfinity Now)
        Another use case: Inline link with inherited text style except font-color
        Example: Like Xfinity? text(Shop Now|https://www.xfinity.com|Shop Xfinity Now)

        2. Link with primary button style
        Example: pri(Shop Now|https://www.xfinity.com|Shop Xfinity Now)

        3. Link with secondary button style
        Example: sec(Shop Now|https://www.xfinity.com|Shop Xfinity Now)

        4. Text with inherited text style but overwritten with bold font
        Example: bold(Bugs!)

        5. Text with inherited text style but with strikethrough
        Example: strike(Bugs!) Feature!

    Params (can be used in any of the functions above):

        1. Link with accessibility text
        Example: text(Shop Now|https://www.xfinity.com|Shop Xfinity Now)

        2. Link without accessibility text
        Example: text(Shop Now|https://www.xfinity.com)

        3. Link that opens in a new tab
        Example: text(tab|Shop Now|https://www.xfinity.com|Shop Xfinity Now)
*/

export interface TextDecorationInfo {
    newTab?: boolean;
    displayText: string;
    fullUrl?: string;
    a11yText?: string;
    isPlainText: boolean;
    isLink?: boolean;
    isPrimary?: boolean;
    isSecondary?: boolean;
    isBold?: boolean;
    isStrikeThrough?: boolean;
}

export interface LinkDecorationInfo extends TextDecorationInfo{
    uiSref?: string;
    uiParams?: object;
    absoluteUrl?: string;
}

@Injectable({
    providedIn: 'root'
})
export class Decorator {
    public static XM_PATH: RegExp = /^\/mobile(.*)/;
    private static TEXT_FUNCTION_NAME: string = 'text';
    private static PRI_FUNCTION_NAME: string = 'pri';
    private static SEC_FUNCTION_NAME: string = 'sec';
    private static BOLD_FUNCTION_NAME: string = 'bold';
    private static STRIKE_FUNCTION_NAME: string = 'strike';
    private static ALL_REPLACEABLES: RegExp = /(text|pri|sec|bold|strike)\((.+?)\)/g;
    private static OPEN_IN_NEW_TAB: string = 'tab';
    private static PLAIN_TEXT_SPLITTER: string = '[]';
    private static PARAM_SPLITTER: string = '|';

    public static transformReplaceable(functionName: string, paramString: string): TextDecorationInfo {
        const replaceableParams: string[] = paramString.split(Decorator.PARAM_SPLITTER);
        const newTab: boolean = replaceableParams[0] === Decorator.OPEN_IN_NEW_TAB;
        const startingIndex: number = newTab ? 1 : 0;
        const isBold: boolean = functionName === Decorator.BOLD_FUNCTION_NAME;
        const isStrikeThrough: boolean = functionName === Decorator.STRIKE_FUNCTION_NAME;

        return {
            newTab,
            displayText: replaceableParams[startingIndex],
            fullUrl: replaceableParams[startingIndex + 1],
            a11yText: replaceableParams[startingIndex + 2],
            isPlainText: isBold || isStrikeThrough,
            isLink: functionName === Decorator.TEXT_FUNCTION_NAME,
            isPrimary: functionName === Decorator.PRI_FUNCTION_NAME,
            isSecondary: functionName === Decorator.SEC_FUNCTION_NAME,
            isBold,
            isStrikeThrough
        };
    }

    public static isDecorated(input: string): boolean {
        return Decorator.ALL_REPLACEABLES.test(input);
    }

    constructor(urlService: UrlService, @Inject(CONFIG_TOKEN) config: IXMOptions) {
        Object.assign(this, { urlService, config });
    }

    public parseTextDecoration(input: string): TextDecorationInfo[] {
        if (!Decorator.isDecorated(input)) {
            return [ this.getPlainTextDecorationInfo(input) ];
        }

        const completedDecorationInfo: TextDecorationInfo[] = [];
        const replaceableDecorationInfo: TextDecorationInfo[] = [];

        const stringWithSplitter: string = input.replace(Decorator.ALL_REPLACEABLES, (_replaceableWithFunction: string, functionName: string, paramString: string) => {
            const transformed: TextDecorationInfo = Decorator.transformReplaceable(functionName, paramString);

            replaceableDecorationInfo.push(transformed);

            return Decorator.PLAIN_TEXT_SPLITTER;
        });

        stringWithSplitter.split(Decorator.PLAIN_TEXT_SPLITTER).forEach((plainText: string, index: number) => {
            if (plainText) {
                completedDecorationInfo.push(this.getPlainTextDecorationInfo(plainText));
            }

            if (replaceableDecorationInfo[index]) {
                completedDecorationInfo.push(replaceableDecorationInfo[index]);
            }
        });

        return completedDecorationInfo;
    }

    public parseLinkDecoration(inputString: string): LinkDecorationInfo[] {
        return this.parseTextDecoration(inputString).map((textInfo: TextDecorationInfo) => {
            if (!textInfo.fullUrl) {
                return textInfo;
            }

            const linkInfo: LinkDecorationInfo = textInfo;

            linkInfo.absoluteUrl = textInfo.fullUrl;

            return linkInfo;
        });
    }

    public createCrossAppUrl(fullUrl: string): string {
        if (fullUrl.startsWith('/')) {
            return `${window.location.origin}${fullUrl}`;
        } else {
            return fullUrl;
        }
    }

    private getPlainTextDecorationInfo(text: string): TextDecorationInfo {
        return {
            displayText: text,
            isPlainText: true
        };
    }
}

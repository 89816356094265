import { Component, ElementRef, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Util } from 'services/util';

import { Cloudinary } from 'services/cloudinary';
import { WindowReference } from 'services/window';
import { BreakpointWidth, CONFIG_TOKEN } from 'core/constants';
import { IXMOptions } from 'core/interfaces';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
    selector: 'xm-media-image',
    styleUrls: [ './image.scss' ],
    templateUrl: './image.html'
})
export class XmMediaImageComponent implements OnInit, OnDestroy, OnChanges {
    private static SCALAR: number = 60;
    private static RETINA_SCALE: number = 2;

    // remove string type during full integration
    @Input() public imageOptions: MediaImageOptions;
    @Input() public delay: number;
    @Input() public cmsMediaImage: CmsMediaImage;
    @Input() public cmsMediaOptionsImage: CmsMediaOptionsImage;
    @Input() public smallIcon?: boolean;
    @Input() public isStaticSize?: boolean = false;
    // Convenience inputs for local images until Cloudinary is sorted out
    @Input() public staticImagePath?: string;
    @Input() public imageSource?: string;
    @Input() public staticImageAlt?: string;

    // Use pixelImageOptions as input for getting images per pixel basis
    // Only for banners. Try NOT to use for anything else
    @Input() public pixelImageOptions: MediaBannerImageOptions[];
    @Input() public size: string;

    public isSVG: boolean;
    public imageUrl: string;
    public imageUrl2x: string;
    public imageAlt: string;
    public buyStaticUrl: string;
    public sharedStaticUrl: string;
    public imgSrc?: string;

    private currentBreakpoint: Breakpoint;
    private element: ElementRef;
    private subscriptions: Subscription[] = [];
    private triedSecondBounding: boolean = false;
    private descendingOrderedBreakpoints: number[] = [];
    private config: IXMOptions;

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions, element: ElementRef, windowReference: WindowReference, breakpointObserver: BreakpointObserver) {
        Object.assign(this, { element, config });

        this.subscriptions.push(windowReference.breakpoint.subscribe((breakpoint: Breakpoint) => {
            this.currentBreakpoint = breakpoint;
            this.checkForBreakpoints();
        }));

        this.subscriptions.push(windowReference.windowWidth.subscribe(() => {
            this.checkForWidth();
        }));

        this.subscriptions.push(
            breakpointObserver.observe([
                `(max-width: ${BreakpointWidth.TABLET}px)`
            ]).subscribe((result: BreakpointState) => {
                if (result.matches) {
                    this.checkForBreakpoints();
                    this.checkForWidth();
                }
            }));
    }

    public ngOnInit(): void {
        this.setData();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && (changes?.imageOptions || changes?.pixelImageOptions)) {
            this.checkForBreakpoints();
            this.checkForWidth();
        }
        this.setData();
        this.setHoverSubMenu();
    }

    public setData(): void {
        this.buyStaticUrl = this.config.BUY_STATIC_BASE_URL;
        this.sharedStaticUrl = this.config.SHARED_STATIC_BASE_URL;

        if (this.pixelImageOptions) {
            // sort keys in descending order
            this.descendingOrderedBreakpoints = this.pixelImageOptions.map((imageObject: MediaBannerImageOptions) => imageObject.breakpoint)
                .sort((value1: number, value2: number) => value2 - value1);
        } else if (this.cmsMediaImage) {
            this.imageOptions = {
                mobile: Cloudinary.generateMediaFromCms(this.cmsMediaImage)
            };
        } else if (this.cmsMediaOptionsImage) {
            this.imageOptions = Cloudinary.generateMediaOptionsFromCms(this.cmsMediaOptionsImage);
        }

        if (this.delay === undefined) {
            this.checkForBreakpoints();
            this.checkForWidth();
        } else {
            setTimeout(() => {
                this.checkForBreakpoints();
                this.checkForWidth();
            }, this.delay);
        }

        this.setImgSrc(this.staticImagePath);
    }
    
    public setImgSrc(staticImagePath: string): void {
        if (this.imageSource === 'buy_static_base_url') {
            this.imgSrc = `${this.buyStaticUrl}/images/${staticImagePath}`;
        } else if (this.imageSource === 'shared_static_base_url') {
            this.imgSrc = `${this.sharedStaticUrl}/images/${staticImagePath}`;
        }
    }

    public setHoverSubMenu(): void {
        if (this.staticImagePath === 'arrow_right_blue.svg' || this.staticImagePath === 'arrow_right_black.svg') {
            this.imgSrc = `${this.buyStaticUrl}/images/${this.staticImagePath}`;
        }
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    private checkForBreakpoints(): void {
        if (!this.imageOptions) {
            return;
        }

        let currentImage: MediaImageType = this.findLargeDesktop();
        if (this.currentBreakpoint.isMobile) {
            currentImage = this.findMobile();
        } else if (this.currentBreakpoint.isTablet) {
            currentImage = this.findTablet();
        } else if (this.currentBreakpoint.isSmallDesktop) {
            currentImage = this.findSmallDesktop();
        }

        this.createImagePaths(currentImage);
    }

    private checkForWidth(): void {
        if (!this.pixelImageOptions || !this.pixelImageOptions.length) {
            return;
        }
        this.descendingOrderedBreakpoints = this.pixelImageOptions.map((imageObject: MediaBannerImageOptions) => imageObject.breakpoint)
            .sort((value1: number, value2: number) => value2 - value1);
        // find highest width in array greater than windowWidth
        const highestWidth: number = this.descendingOrderedBreakpoints
            .find((key: number) => window.innerWidth >= key) || this.descendingOrderedBreakpoints[this.descendingOrderedBreakpoints.length - 1];
        this.pixelImageOptions.map((bannerImageOptions: MediaBannerImageOptions) => {
            if (bannerImageOptions.breakpoint === highestWidth) {
                this.createImagePaths(Cloudinary.generateMediaFromCms(bannerImageOptions.image));
            }
        });
    }

    private createImagePaths(currentImage: MediaImageType): void {
        if (currentImage.useBoundingBox) {
            const box: ClientRect = this.element.nativeElement.getBoundingClientRect();
            const width: number = this.roundUp(box.width);
            const height: number = this.roundUp(box.height);

            if (width > 0 || height > 0) {
                if (this.priorityHeight() && height > 0) {
                    this.imageUrl = Cloudinary.generateUrl(-1, height, currentImage);
                    this.imageUrl2x = `${Cloudinary.generateUrl(-1, height * XmMediaImageComponent.RETINA_SCALE, currentImage)} 2x`;
                } else if (this.priorityWidth() && width > 0) {
                    this.imageUrl = Cloudinary.generateUrl(width, -1, currentImage);
                    this.imageUrl2x = `${Cloudinary.generateUrl(width * XmMediaImageComponent.RETINA_SCALE, -1, currentImage)} 2x`;
                } else {
                    this.imageUrl = Cloudinary.generateUrl(width, height, currentImage);
                    this.imageUrl2x = `${Cloudinary.generateUrl(width * XmMediaImageComponent.RETINA_SCALE, height * XmMediaImageComponent.RETINA_SCALE, currentImage)} 2x`;
                }
            } else if (!this.triedSecondBounding) {
                this.triedSecondBounding = true;
                setTimeout(() => {
                    this.checkForBreakpoints();
                    this.checkForWidth();
                });
            }
        } else {
            const width: number = currentImage.width || -1;
            const height: number = currentImage.height || -1;

            this.imageUrl = Cloudinary.generateUrl(width, height, currentImage);
            this.imageUrl2x = `${Cloudinary.generateUrl(width * XmMediaImageComponent.RETINA_SCALE, height * XmMediaImageComponent.RETINA_SCALE, currentImage)} 2x`;
        }

        this.imageAlt = currentImage.alt;
        this.isSVG = this.imageUrl && this.imageUrl.endsWith('.svg');
    }

    private roundUp(value: number): number {
        return Math.ceil(value / XmMediaImageComponent.SCALAR) * XmMediaImageComponent.SCALAR;
    }

    private findLargeDesktop(): MediaImageType {
        if (this.imageOptions.largeDesktop) {
            return this.imageOptions.largeDesktop;
        }

        return this.findSmallDesktop();
    }

    private findSmallDesktop(): MediaImageType {
        if (this.imageOptions.smallDesktop) {
            return this.imageOptions.smallDesktop;
        }

        return this.findTablet();
    }

    private findTablet(): MediaImageType {
        if (this.imageOptions.tablet) {
            return this.imageOptions.tablet;
        }

        return this.findMobile();
    }

    private findMobile(): MediaImageType {
        return this.imageOptions.mobile;
    }

    private priorityHeight(): boolean {
        return this.size === 'height';
    }

    private priorityWidth(): boolean {
        return this.size === 'width';
    }
}

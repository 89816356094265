/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CmsCore, CmsFinancingOptions } from 'store/cms/models';
import { Modal, XmStore, XmStoreUtil } from 'core/services/';
import { Subscription } from 'rxjs';

@Component({
    selector: 'xm-box-radio',
    styleUrls: [ './box-radio.scss' ],
    templateUrl: './box-radio.html'
})

export class XmBoxRadioComponent implements OnInit {
    
    @Input() public option: XmRadioOption;
    @Input() public radioOption: XmRadioOption;
    @Input() public smallMainLabel: boolean = true;
    @Input() public labelClass?: string = '';
    @Input() public isExpandableEnable: boolean = true;
    @Input() public isRemoveBgColor: boolean = false;
    @Input() public boxlabel: string;
    // eslint-disable-next-line @typescript-eslint/typedef
    @Input() public infoTextBulletPoints = [];
    @Output() public changed: EventEmitter<string> = new EventEmitter<string>();
    public pageData: CmsFinancingOptions;
    public labelFor: string;
    public ariaDescribedby: string;
    public einModalData: DescriptiveModalData;
    
    private modal: Modal;
    private subscriptions: Subscription[] = [];
    private xmStore: XmStore;

    constructor(xmStore: XmStore, modal: Modal) {    
        Object.assign(this, { xmStore, modal }); 
    }

    public ngOnInit(): void {
        this.labelFor = `${this.option.groupName}-${this.option.labelFor || this.option.label}`.replace(/\s+/g, '');
        this.ariaDescribedby = this.option.ariaDesc ? `aria-${this.option.groupName}${this.option.ariaDesc}` : `aria-${this.option.groupName}`;
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore), (result: CmsCore) => {
            this.einModalData = result.modals.einModal;
        }));
    }

    public viewEinModal(): void {
        this.modal.descriptive(this.einModalData);
    }

    public select(status: any): void {
        this.changed.emit(status.srcElement.id);
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */
}
import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { TransitionPromise } from '@uirouter/core';
import { BreakpointWidth } from 'core/constants';
@Directive({
    selector: '[promiseBtn]'
})

/** custom loading promise button */
export class CbmPromiseBtn<T> {
    @Input('promiseBtn') public promise: Promise<T> | TransitionPromise;

    public isMobileView: boolean;
    public isTabletView: boolean;

    private spinnerElement: HTMLElement;
    private buttonContent: string; // to store the buton text

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    @HostListener('click') public onClick(): void {
        this.isMobileView = window.innerWidth < BreakpointWidth.TABLET;
        this.isTabletView = window.innerWidth >= BreakpointWidth.TABLET;
        this.buttonContent = this.elementRef.nativeElement.textContent;

        // to hide the button text when spinner starts
        this.elementRef.nativeElement.textContent = '';
        // create spinner container
        this.spinnerElement = this.renderer.createElement('div');
        if (this.isMobileView) { 
            this.renderer.setAttribute(this.spinnerElement, 'style', `
            position: relative;
            top: 60%;
            bottom: 60%;
            left: 120%;
            right: 80%;
            transform: translate(-90%, -10%);
        `);
        } else {
            this.renderer.setAttribute(this.spinnerElement, 'style', `
            position: relative;
            top: 60%;
            bottom: 60%;
            left: 90%;
            right: 80%;
            transform: translate(-90%, -10%);
        `);
        }

        // create spinner
        const spinner: ElementRef = this.renderer.createElement('div');
        this.renderer.setAttribute(spinner, 'style', `
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px; /* Adjust the size as needed */
            height:100%;
        `);

        // Create and append three white loading circles to the spinner element
        for (let i: number = 0; i < 3; i++) {
            const circle: ElementRef = this.renderer.createElement('div');
            this.renderer.setAttribute(circle, 'style', `
                content: '';
                width: 12px;
                height: 12px;
                background-color: #fff;
                border-radius: 50%;
                margin: 4px 8px; /* Adjust the margin as needed to control spacing */
                animation: bounce 1.4s infinite ease-in-out ${i * 0.32}s;
            `);
            this.renderer.appendChild(spinner, circle);
        }

        // Append the spinner to the container and then to the button
        this.renderer.appendChild(this.spinnerElement, spinner);
        this.renderer.appendChild(this.elementRef.nativeElement, this.spinnerElement);

        // Disable the button
        this.elementRef.nativeElement.disabled = true;
    
        // wait for promise to be defined  
        setTimeout(() => {
            this.promise.then(() => {
                // Enable the button, restore the original text content, and remove the spinner when the Promise is resolved
                this.elementRef.nativeElement.disabled = false;
                this.elementRef.nativeElement.textContent = this.buttonContent;
                if (this.spinnerElement) {
                    this.renderer.removeChild(this.elementRef.nativeElement, this.spinnerElement);
                }
            })
                .catch(() => {
                // Enable the button, restore the original text content, and remove the spinner when the Promise is rejected
                    this.elementRef.nativeElement.disabled = false;
                    this.elementRef.nativeElement.textContent = this.buttonContent;
                    if (this.spinnerElement) {
                        this.renderer.removeChild(this.elementRef.nativeElement, this.spinnerElement);
                    }
                });
        });
    }
}

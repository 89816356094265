import { PlanVariant } from './plan';
import { PlanSku } from 'core/constants';

export class PaymentPlanVariant extends PlanVariant {
    declare public id: PlanSku;

    public get isFinanced(): boolean {
        return this.id === PlanSku.FINANCED;
    }
}

import { ChildModel } from 'store/model-base';
import { PlanGroup } from 'store/plan/models/group/plan-group';

import { RatePlan } from 'store/plan/models/option/rate-plan';

export class RatePlanGroup extends PlanGroup {
    declare public options: RatePlan[];

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'options',
            model: RatePlan
        }];
    }
}

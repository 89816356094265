import { ModelBase } from 'store/model-base';
import { StoreAction } from 'store/actions';
import { LogFields } from './model/LogFields';
import { HttpClient } from '@angular/common/http';
import { buildHeaders } from 'core/services';
import { SessionStorage } from '../storage/session';
import { ApiChannel, StorageToken} from 'core/constants';
import { InjectorInstance } from 'src/xm/module';
import { getCurrentEnvironment } from './LogHelper';

export class Logger {
    
    public static sessionStorage: SessionStorage = new SessionStorage();
    public static applicationID: string ='comcastbusinessmobilebuy';
 
    public static log(logFields: LogFields): void {

        const httpClient: HttpClient = InjectorInstance.get<HttpClient>(HttpClient);

        logFields.ApplicationId = this.applicationID;
        logFields.AppType = 'ClientApp';
        logFields.TrackingId = this.sessionStorage.get(StorageToken.VISITOR_SESSION_ID);
        logFields.CreateDateUtc = new Date().toISOString();
        logFields.Environment = getCurrentEnvironment();

        httpClient.post('/event', logFields, {
            headers: buildHeaders({
                apiChannel: ApiChannel.KIBANA_CODEBIG_API
            })
        }).subscribe(
            // eslint-disable-next-line @typescript-eslint/typedef
            (result) => {
                // eslint-disable-next-line no-console
                console.log('Successfully Logged into kibana', result);
            },
            // eslint-disable-next-line @typescript-eslint/typedef
            (error) => {
                // eslint-disable-next-line no-console
                console.log('Error in logging to kibana', error);
            }
        );
    }

    //TO DO : Log buffer
}

ModelBase.fetchMapping[StoreAction.POST_LOG] = Logger.log;

/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ModelBase } from 'store/model-base';
import { buildHeaders, buildParams, XmStore } from 'core/services';
import {
    WatchesActivationRequest,
    WatchesActivationStatus,
    WatchesAddress,
    WatchesAddressValidation,
    WatchesPairEligibility,
    WatchesPairEligibilityRequestId
} from './models';
import { ApiChannel } from 'core/constants';
import { StoreAction } from 'store/actions';
import { logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';

@Injectable()
export class WatchesApi {
    public static pairWatchWithMobile(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<WatchesActivationRequest> {
        return http.post(`/lines/${params.hostIccid}/pair/${params.imei}`, params.payload, {
            headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }),
            params: buildParams({ newEsim: params.newEsim })
        }).pipe(
            map((response: ApiResponse) => WatchesActivationRequest.create<WatchesActivationRequest>(response)),                 
            catchError(err => logAndHandleError(err, '/lines/{params.hostIccid}/pair/{params.imei}', OperationType.POST, undefined, JSON.stringify(params)))
        );
    }

    public static getOngoingActivationRequest(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<WatchesActivationRequest> {
        return http.get(`/provisioning/requests/imei/${params.imei}/hostIccid/${params.hostIccid}`, { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }) }).pipe(
            map((response: ApiResponse) => WatchesActivationRequest.create<WatchesActivationRequest>(response)),                 
            catchError(err => logAndHandleError(err, '/provisioning/requests/imei/{params.imei}/hostIccid/{params.hostIccid}', OperationType.GET))
        );
    }

    public static getActivationStatus(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<WatchesActivationStatus> {
        return http.get(`/provisioning/requests/${params.requestId}`, { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }) }).pipe(
            map((response: ApiResponse) => WatchesActivationStatus.create<WatchesActivationStatus>(response)),                 
            catchError(err => logAndHandleError(err, '/provisioning/requests/{params.requestId}', OperationType.GET))
        );
    }

    public static getPairingEligibilityRequestID(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<WatchesPairEligibilityRequestId> {
        return http.get(`/lines/${params.hostIccid}/pairing/${params.imei}/eligibility`, { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }) }).pipe(
            map((response: ApiResponse) => WatchesPairEligibilityRequestId.create<WatchesPairEligibilityRequestId>(response)),                 
            catchError(err => logAndHandleError(err, '/lines/{params.hostIccid}/pairing/{params.imei}/eligibility', OperationType.GET))
        );
    }

    public static getPairingEligibility(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<WatchesPairEligibility> {
        return http.get(`/pairing/eligibility/requests/${params.requestId}`, { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }) }).pipe(
            map((response: ApiResponse) => WatchesPairEligibility.create<WatchesPairEligibility>(response)),                 
            catchError(err => logAndHandleError(err, '/pairing/eligibility/requests/{params.requestId}', OperationType.GET))
        );
    }

    public static validAddress(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<WatchesAddressValidation> {
        return http.post('/e911/address/validation', params, { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }) }).pipe(
            map((response: ApiResponse) => WatchesAddressValidation.create<WatchesAddressValidation>({
                enteredAddress: params,
                ...response
            })),
            catchError(() => of(WatchesAddressValidation.create<WatchesAddressValidation>({ enteredAddress: params })))
        );
    }

    public static getAddress(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<WatchesAddress> {
        return http.get(`/lines/${params.hostIccid}/pair/${params.imei}/e911address`, { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }) }).pipe(
            map((address: WatchesAddress) => WatchesAddress.create<WatchesAddress>(address)),                 
            catchError(err => logAndHandleError(err, '/lines/{params.hostIccid}/pair/{params.imei}/e911address', OperationType.GET))
        );
    }

    public static saveAddress(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<WatchesAddress> {
        return http.post(`/lines/${params.hostIccid}/pair/${params.imei}/e911address`, params.address, { headers: buildHeaders({ apiChannel: ApiChannel.GATEWAY }) }).pipe(
            map(() => WatchesAddress.create<WatchesAddress>(params.address)),                 
            catchError(err => logAndHandleError(err, '/lines/{params.hostIccid}/pair/{params.imei}/e911address', OperationType.POST, undefined, JSON.stringify(params)))
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_WATCH_ADDRESS_VALIDATION] = WatchesApi.validAddress;
ModelBase.persistMapping[StoreAction.POST_WATCH_ACTIVATION] = WatchesApi.pairWatchWithMobile;
ModelBase.persistMapping[StoreAction.GET_ONGOING_ACTIVATION_REQUEST] = WatchesApi.getOngoingActivationRequest;
ModelBase.fetchMapping[StoreAction.GET_WATCH_ACTIVATION_STATUS] = WatchesApi.getActivationStatus;
ModelBase.fetchMapping[StoreAction.GET_WATCH_ACTIVATION_STATUS] = WatchesApi.getActivationStatus;
ModelBase.fetchMapping[StoreAction.GET_WATCH_ADDRESS] = WatchesApi.getAddress;
ModelBase.persistMapping[StoreAction.POST_WATCH_ADDRESS] = WatchesApi.saveAddress;
ModelBase.fetchMapping[StoreAction.GET_WATCH_PAIR_ELIGIBITLITY_REQUESTID] = WatchesApi.getPairingEligibilityRequestID;
ModelBase.fetchMapping[StoreAction.GET_WATCH_PAIR_ELIGIBILITY] = WatchesApi.getPairingEligibility;

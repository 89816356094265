import { ChildModel, ModelBase } from 'store/model-base';
import { PlanGroupType } from 'core/constants';
import { InsurancePlanVariant } from './variant/insurance-plan-variant';

export class InsurancePlans extends ModelBase {

    public static storeName: string = 'insurancePlans';

    public xmcVariant: InsurancePlanVariant;
    public xmcVariantList: InsurancePlanVariant[];
    public acpVariant: InsurancePlanVariant;

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public static create<T extends ModelBase>(initData: ApiResponse): T {
        const toReturn: InsurancePlans = super.create<InsurancePlans>(initData);

        toReturn.xmcVariantList = initData.filter(plan => plan.subCategories.includes(PlanGroupType.XMC));

        toReturn.xmcVariant = initData.find(plan => plan.subCategories.includes(PlanGroupType.XMC));
        toReturn.acpVariant = initData.find(plan => plan.subCategories.includes(PlanGroupType.ACP));
        
        return <T> <any> toReturn;
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'xmcVariant',
            model: InsurancePlanVariant
        }, {
            attrName: 'acpVariant',
            model: InsurancePlanVariant
        }];
    }

    public get xmcProtectionPlan(): InsurancePlanVariant {
        return this.xmcVariant;
    }

    public get acpProtectionPlan(): InsurancePlanVariant {
        return this.acpVariant;
    }

    public get isMultipleorNoXMCPlan(): boolean {

        return this.xmcVariantList?.length > 1 || this.xmcVariantList?.length === 0 ? true : false;

    }
}


import { ProtectionPlan } from 'store/plan/models/option/protection-plan';
import { PlanGroup } from './plan-group';
import { ChildModel } from 'store/model-base';

export class ProtectionPlanGroup extends PlanGroup {
    declare public options: ProtectionPlan[];

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'options',
            model: ProtectionPlan
        }];
    }
}

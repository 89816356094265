/* eslint-disable @typescript-eslint/typedef */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ModelBase } from 'store/model-base';
import { ProductCard, ProductDetails } from './models';
import { StoreAction } from 'store/actions';
import { ApiChannel, BuyInfoAccountType, TrialFeatures } from 'core/constants';
import { buildHeaders, buildParams, getCombinedTrialFeatures, XmStore } from 'core/services';
import { Catalog } from 'store/product/models';
import { logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';
import { IXMOptions } from 'core/interfaces';

@Injectable()
export class ProductApi {   

    public static getCatalog(_xmStore: XmStore, http: HttpClient, params: ApiParams, config: IXMOptions): Observable<Catalog> { 
        
        return http.get('/products', {
            headers: buildHeaders({ apiChannel: ApiChannel.PCAT }),
            params: buildParams({...params, trialFeatures: config.ENABLE_DDA_PROMO ? TrialFeatures.DDA : undefined })
        })
            .pipe(         
                map((response: Catalog) => Catalog.create<Catalog>(response)),
                catchError(err => logAndHandleError(err, '/products', OperationType.GET))          
            );
    }

    public static getCompatibleProducts(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<ProductCard[]> {
        return http.get('/product/compatible', {
            headers: buildHeaders({ apiChannel: ApiChannel.PCAT }),
            params: buildParams(params)
        }).pipe(
            map((response: ProductCard[]) => response.map((product: object) => ProductCard.create<ProductCard>(product))), 
            catchError(err => logAndHandleError(err, '/product/compatible', OperationType.GET))         
        );
    }

    public static getProductDetail(_xmStore: XmStore, http: HttpClient, params: ApiParams, config: IXMOptions): Observable<ProductDetails> {
        params.accountType = !config.ENABLE_XMC_MVE_PLUS ? BuyInfoAccountType.BUSINESS : undefined;               
        const trialFeatures: string = getCombinedTrialFeatures([
            config.ENABLE_ACP_TRIAL ? TrialFeatures.APPLE_CARE : undefined,
            config.ENABLE_DDA_PROMO ? TrialFeatures.DDA : undefined
        ]);
        
        return http.get('/product/details', {
            headers: buildHeaders({ apiChannel: ApiChannel.PCAT }),
            params: buildParams({...params, trialFeatures: trialFeatures })
        }).pipe(
            map((product: ProductDetails) => ProductDetails.create<ProductDetails>({ ...product[0], tps: params.tps, tradeinDeviceGroupId: config.ENABLE_TIPS ? params.tradeinDeviceGroupId : undefined })), 
            catchError(err => logAndHandleError(err, '/product/details', OperationType.GET))    
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_CATALOG] = ProductApi.getCatalog;
ModelBase.fetchMapping[StoreAction.GET_COMPATIBLE_PRODUCTS] = ProductApi.getCompatibleProducts;
ModelBase.fetchMapping[StoreAction.GET_PRODUCT_DETAIL] = ProductApi.getProductDetail;
